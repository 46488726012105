import { PortableText } from '@portabletext/react'
import { animated, useSpring } from '@react-spring/web'
import { useScrollProgression, triggers } from '@kaliber/scroll-progression'
import { lerp } from '@kaliber/math'

import { easeOut } from '/machinery/easings'

import { ImageCover } from '/features/buildingBlocks/Image'

import styles from './Quote.css'

export function Quote({ quote, sourceObject, layoutClassName = undefined }) {
  const { source, role, image } = sourceObject ?? {}
  const hasImage = Boolean(image?.asset)

  return (
    <section data-x='quote' className={cx(styles.component, layoutClassName)}>
      {hasImage && <SourceImage layoutClassName={styles.imageLayout} {...{ image }} />}
      <BlockQuote layoutClassName={styles.blockQuoteLayout} {...{ quote, source, role }} />
    </section>
  )
}

function SourceImage({ image, layoutClassName }) {
  return (
    <div className={cx(styles.componentSourceImage, layoutClassName)}>
      <ImageCover aspectRatio={5 / 7} {...{ image }} />
    </div>
  )
}

function BlockQuote({ quote, source, role, layoutClassName }) {
  const [style, api] = useSpring(() => ({
    '--progress': '0%',
    config: { tension: 300, friction: 40 }
  }))

  const ref = useScrollProgression({
    start: { element: triggers.top(), scrollParent: triggers.custom(0.75) },
    end: { element: triggers.bottom(), scrollParent: triggers.custom(0.66) },
    onChange(progression) {
      api.start({ '--progress': `${lerp({ start: 0, end: 100, input: easeOut(progression) })}%` })
    }
  })

  /** @type {import('@portabletext/react').PortableTextProps['components']} */
  const components = {
    block: {
      normal: ({ children }) => <><span className={source && styles.quoteParagraph}>{children}</span><br /><br /></>,
    }
  }

  return (
    <div className={cx(styles.componentBlockQuote, layoutClassName)} {...{ ref }}>
      {/* @ts-ignore */}
      <animated.q className={styles.quoteHighlightElement} {...{ style }}>
        <PortableText value={quote} {...{ components }} />
      </animated.q>
      {source && <p className={styles.source}>{source}</p>}
      {role && <p className={styles.role}>{role}</p>}
    </div>
  )
}
